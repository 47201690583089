import {httpClient} from "../../shared/shared-services/httpClient";
import {environment} from "@/environment";

const URL = `${environment.ecommerceApiBaseUrl}package-class`;

export default {

  fetchPackageClassesById(sizPackageId) {
    const url = `${environment.ecommerceApiBaseUrl}package-size/${sizPackageId}`;
    return httpClient.get(url)
      .then(res => res.data['data'])
      .catch(error => console.log(error));
  },
  storePackageClass(packageClass) {
    return httpClient.post(URL, packageClass)
      .then(res => res)
      .catch(error => console.log(error));
  },
  updatePackageClass(packageClass, id) {
    let url = URL + `/${id}?_method=PUT`;
    return httpClient.post(url, packageClass)
      .then(res => res)
      .catch(error => console.log(error));
  },
  deletePackageClass(packageClassId) {
    let url = URL + '/' + packageClassId;
    return httpClient.delete(url)
      .then(res => res["data"])
      .catch(error => console.log(error));

  }
};
