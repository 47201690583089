import {httpClient} from "../../shared/shared-services/httpClient";
import {environment} from "@/environment";

const URL = `${environment.ecommerceApiBaseUrl}market`;

export default {

  fetchAllMarkets() {
    return httpClient.get(URL)
      .then(res => res.data['data'])
      .catch(error => console.log(error));
  },
  storeMarket(market) {
    return httpClient.post(URL, market)
      .then(res => res)
      .catch(error => console.log(error));
  },
  updateMarket(market, id) {
    const url = URL + `/${id}?_method=PUT`;
    return httpClient.post(url, market)
      .then(res => res)
      .catch(error => console.log(error));
  },
  /*deleteMarket(marketId, isImage = false) {
    let url = URL + '/' + marketId;
    url = isImage ? url + '/images' : url;
    return httpClient.delete(url)
      .then(res => res["data"])
      .catch(error => console.log(error))
  }*/
};
