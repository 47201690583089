import {httpClient} from "../../shared/shared-services/httpClient";
import {environment} from "@/environment";

const URL = `${environment.ecommerceApiBaseUrl}subProductImages`;

export default {
  getAll() {
    return httpClient.get(URL)
      .then(res => res.data)
      .catch(error => console.log(error));
  },
  store(subProductImage) {
    return httpClient.post(URL, subProductImage)
      .then(res => res.data)
      .catch(error => console.log(error));
  },
  update(subProductImage) {
    return httpClient.post(`${URL}/${subProductImage.id}?_method=PUT`, subProductImage)
      .then(res => res.data)
      .catch(error => console.log(error));
  },
  delete(ids) {
    return httpClient.delete(`${URL}/${ids}`)
      .then(res => res.data)
      .catch(error => console.log(error));
  }
};
