import product from "./productRepository";
import marketRepository from "./marketRepository";
import category from "./categoryRepository";
import store from "./storeRepository";
import variantKey from './variantKeyRepository';
import variantValue from './variantValueRepository';
import subProduct from "./subProductRepository";
import order from "./orderRepository";
import subProductImage from "./subProductImageRepository";
import subProductFeedback from "./subProductFeedback";
import subProductTag from "./subProductTagRepository";
import subProductVariant from "./subProductVariantRepository";
import packageSize from "./packageSizeRepository";
import packageClass from "./packageClassRepository";

export const repositories = {
  product,
  category,
  store,
  variantKey,
  subProduct,
  variantValue,
  order,
  subProductImage,
  subProductTag,
  subProductVariant,
  market: marketRepository,
  subProductFeedback,
  packageSize,
  packageClass
};

export const RepositoryFactory = {
  get: name => repositories[name]
};
