import {httpClient} from "../../shared/shared-services/httpClient";
import {environment} from "@/environment";

const URL = `${environment.ecommerceApiBaseUrl}store`;

export default {

  fetchAllStores() {
    return httpClient.get(URL)
      .then(res => res.data['data'])
      .catch(error => console.log(error));
  },
  fetchStoreById(storeId) {
    let url = URL + `/${storeId}`;
    return httpClient.get(url)
      .then(res => res.data['data'])
      .catch(error => console.log(error));
  },
  storeStore(store) {
    return httpClient.post(URL, store)
      .then(res => res)
      .catch(error => console.log(error));
  },
  updateStore(store, id) {
    let url = URL + `/${id}?_method=PUT`;
    return httpClient.post(url, store)
      .then(res => res)
      .catch(error => console.log(error));
  },
  deleteStore(storeId, isImage = false) {
    let url = URL + '/' + storeId;
    url = isImage ? url + '/images' : url;
    return httpClient.delete(url)
      .then(res => res["data"])
      .catch(error => console.log(error));
  },
  fetchAllStoreAdmins(storeId) {
    let url = URL + `/${storeId}/admin`;
    return httpClient.get(url)
      .then(res => res.data.data)
      .catch(error => console.log(error));
  },
  addStoreAdmin(usersId, storeId) {
    let url = URL + `/${storeId}/admin/${usersId}`;
    return httpClient.post(url)
      .then(res => res)
      .catch(error => console.log(error));
  },
  fetchAllAllowStoreAdminsUsers(storeId) {
    let url = environment.apiBaseURL + `users/store/${storeId}/store_admins`;
    return httpClient.get(url)
      .then(res => res.data.data)
      .catch(error => console.log(error));
  },
  deleteStoreAdmin(usersIds, storeId) {
    let url = URL + `/${storeId}/admin/${usersIds}`;
    return httpClient.delete(url)
      .then(res => res["data"])
      .catch(error => console.log(error));
  },
  /* hiddenStore(id) {
     const url = environment.ecommerceApiBaseUrl + `toggle-hide/store/${id}`;
     return httpClient.post(url)
         .then(res => res.data.data)
         .catch(error => console.log(error));
   }*/
};
