import {httpClient} from "../../shared/shared-services/httpClient";
import {environment} from "@/environment";

const URL = `${environment.ecommerceApiBaseUrl}variantKeys`;


export default {
  getAllVariant() {
    return httpClient.get(URL)
      .then(res => res.data.data)
      .catch(error => console.log(error));
  },
  storeVariant(variant) {
    variant = {
      name: variant.name,
      product_id: variant.product_id
    };
    return httpClient.post(URL, variant)
      .then(res => res.data)
      .catch(error => console.log(error));
  },
  updateVariant(variant) {
    return httpClient.post(`${URL}/${variant.id}?_method=PUT`, variant)
      .then(res => res.data)
      .catch(error => console.log(error));
  },

  deleteVariant(ids) {
    return httpClient.delete(`${URL}/${ids}`)
      .then(res => res.data)
      .catch(error => console.log(error));
  }
};
