<template>
  <vs-row vs-type="flex">
    <vs-col
      vs-type="flex"
      vs-justify="center"
      vs-align="center"
      vs-w="12"
      class="mb-5"
    >
      <vs-card>
        <vs-row>
          <vs-col
            vs-w="4"
            class="p-2"
            v-if="subProduct.sub_product_images.length !== 0"
          >
            <vs-card>
              <div
                class="carousel-example"
                :dir="$vs.rtl ? 'rtl' : 'ltr'"
                :key="$vs.rtl"
              >
                <swiper :options="swiperOption">
                  <swiper-slide
                    v-for="image in subProduct.sub_product_images"
                    :key="image.id"
                  >
                    <img
                      class="responsive"
                      v-lazy="image.image_url"
                      alt="banner"
                      style="height: 300px; object-fit: cover"
                    >
                  </swiper-slide>
                  <div
                    class="swiper-pagination"
                    slot="pagination"
                  />
                </swiper>
              </div>
            </vs-card>
          </vs-col>
          <vs-col
            vs-w="4"
            class="p-2"
            v-if="subProduct.id !== undefined"
          >
            <vs-card>
              <h3 class="mb-2">
                المعرف : {{ subProduct.id }}
              </h3>
              <h4 class="mb-2">
                الاسم : {{ subProduct.name }}
              </h4>
              <p class="mb-2">
                الوصف : {{ subProduct.desc }}
              </p>
              <p class="mb-2">
                السعر: {{ subProduct.price }}
              </p>
              <p class="mb-2">
                الحالة: {{ subProduct.stock_status }}
              </p>
              <p class="mb-2">
                وقت تجهيز المنتج : {{ subProduct.process_time }}
              </p>
              <p class="mb-2">
                حجم تعليف المنتج : {{ subProduct.package_size.size }}
              </p>
              <p
                v-if="subProduct.barcode"
                class="mb-2"
              >
                الباركود : {{ subProduct.barcode }}
              </p>
              <p class="mb-2">
                التقييم : {{ subProduct.rate }}
              </p>
              <div class="mb-2">
                <p>التاغات :</p>
                <div class="mb-10">
                  <vs-chip
                    v-for="(tag, index) in subProduct.tags"
                    :key="index"
                    transparent
                    color="primary"
                  >
                    {{ tag.name }}
                  </vs-chip>
                </div>
              </div>
              <br>
            </vs-card>
          </vs-col>
          <vs-col
            vs-w="4"
            class="p-2"
            v-if="subProduct.sub_product_variants !== undefined"
          >
            <vs-row>
              <vs-card>
                <vs-col vs-w="6">
                  <h5 class="mb-2">
                    الخصائص :
                  </h5>
                  <p
                    class="mb-2"
                    v-for="(variant, index) in subProduct.sub_product_variants"
                    :key="index"
                  >
                    <span><strong>{{ variant.key }}</strong> :{{ variant.value }}</span>
                  </p>
                </vs-col>
              </vs-card>
            </vs-row>
          </vs-col>
        </vs-row>
      </vs-card>
    </vs-col>
    <vs-col
      vs-type="flex"
      vs-justify="center"
      vs-align="center"
      vs-w="12"
      class="mb-5"
    >
      <vs-card>
        <vs-tabs
          alignment="fixed"
          color="primary"
          v-model="index"
        >
          <vs-tab
            v-for="route in routes"
            :key="route.id"
            :label="route.label"
            icon-pack="feather"
            :icon="route.icon"
            @click="redirect(route.name)"
          />
        </vs-tabs>
        <router-view
          v-if="subProduct.id !== undefined"
          :sub-product="subProduct"
        />
      </vs-card>
    </vs-col>
  </vs-row>
</template>

<script>
import {RepositoryFactory} from '../../repositories/ecommerceRepositoryFactory';
import 'swiper/dist/css/swiper.min.css';
import {swiper, swiperSlide} from 'vue-awesome-swiper';

const subProductRepository = RepositoryFactory.get('subProduct');
export default {
  name: "SubProductDetails",
  data() {
    return {
      swiperOption: {
        pagination: {
          el: '.swiper-pagination'
        }
      },
      subProduct: {
        sub_product_images: []
      },
      index: 0,
      routes: [
        {
          name: 'subProductTags',
          index: 0,
          icon: 'icon-hash',
          label: 'التاغات'
        },
        {
          name: 'subProductVariants',
          index: 1,
          icon: 'icon-sliders',
          label: 'الخصائص'
        },
        {
          name: 'subProductImages',
          index: 2,
          icon: 'icon-image',
          label: 'الصور'
        },
        {
          name: 'subProductFeedbacks',
          index: 3,
          icon: 'icon-message-square',
          label: 'المراجعات'
        }
      ]
    };
  },
  components: {
    swiper,
    swiperSlide
  },
  methods: {
    redirect(route) {
      this.$router.push({name: route});
    }
  },
  created() {
    subProductRepository.get(this.$route.params.subProductId).then(response => {
      this.subProduct = response.data;
    });

    let route = this.routes.find((r) => {
      return r.name === this.$route.name;
    });

    if (route !== undefined)
      this.index = route.index;
    else
      this.redirect(this.routes[0].name);
  }
};
</script>
